import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const InfoPage = () => {
  return (
    <Layout activePage="info">
      <Seo title="Адвокатский кабинет Храпова Юрия Николаевича"/>
      <h2 className="main-title">
          Полезная информация
      </h2>
      <p className="p-style3"><a className="a-style" href="/doc/arbitr-kodeks.pdf" target="_blank">Арбитражный процессуальный кодекс РФ</a></p>
      <p className="p-style3"><a className="a-style" href="/doc/gosposhlina.pdf" target="_blank">Госпошлина</a></p>
      <p className="p-style3"><a className="a-style" href="/doc/zayavlenie-v-policiu.pdf" target="_blank">Заявление в полицию о преступлении (УВД ЦАО Москвы)</a></p>
      <p className="p-style3"><a className="a-style" href="/doc/izmenenie-gk.pdf" target="_blank">Изменения в ГК РФ № 100-ФЗ от 07.05.2013г.</a></p>
      <p className="p-style3"><a className="a-style" href="/doc/sudy.pdf" target="_blank">Районные суды г. Москвы</a></p>
      <p className="p-style3"><a className="a-style" href="/doc/fz-ob-advokat.pdf" target="_blank">ФЗ Об адвокатской деятельности и адвокатуре</a></p>
    </Layout>
  )
}

export default InfoPage
